<template>
  <BContainer class="my-5">
    <BRow>
      <BCol>
        <BRow class="mb-3 mb-lg-4">
          <BCol>
            <Header1 to="widget-edit" v-bind:id="id" v-bind:showEdit="true" v-if="widget">
              <h1 class="h2">
                <BLink v-bind:href="widget.website" target="_blank">{{ widget.websiteName }}</BLink>
                <BIconArrowRight class="mx-2" />
                <BLink v-bind:href="widget.reviewWebsite" target="_blank">{{ widget.reviewWebsiteName }}</BLink>
              </h1>
            </Header1>
            <WidgetCharts v-if="widget" v-bind:id="widget.id" />
          </BCol>
        </BRow>
        <BRow>
          <BCol>
            <Header2 title="Events" />
            <WidgetEvent v-if="widget" v-bind:id="widget.id" />
          </BCol>
        </BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Header1 from "./Header1.vue";
import Header2 from "./Header2.vue";
import WidgetCharts from "./WidgetCharts.vue";
import WidgetEvent from "./WidgetEvent.vue";

export default {
  metaInfo: {
    title: "Widget"
  },
  components: {
    Header1,
    Header2,
    WidgetCharts,
    WidgetEvent
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    widget() {
      return this.$store.getters["widget/widgetById"](this.id);
    }
  },
  watch: {
    id: {
      handler(value) {
        this.$store.commit("widget/setWidgetActive", value);
      },
      immediate: true
    }
  }
};
</script>
