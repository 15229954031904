<template>
  <VChart class="chart" autoresize :option="option" />
</template>

<script>
import {
  AxisPointerComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { use, registerTheme } from "echarts/core";
import boostr from "../eChartsBoostr";
import VChart, { THEME_KEY } from "vue-echarts";

registerTheme("boostr", boostr);

use([
  AxisPointerComponent,
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent
]);

export default {
  name: "WidgetRating",
  components: {
    VChart
  },
  props: ["id"],
  provide: {
    [THEME_KEY]: "boostr"
  },
  computed: {
    widget() {
      return this.$store.getters["widget/widgetById"](this.id);
    },
    total() {
      return (
        this.widget.stats.s1 + this.widget.stats.s2 + this.widget.stats.s3 + this.widget.stats.s4 + this.widget.stats.s5
      );
    },
    option() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => {
            const param = params[0];
            const percent = this.total >= 0 && param.data[1] ? ((param.data[1] * 100) / this.total).toFixed() : 0;
            return `${param.data[0]} represents ${percent}% of ratings`;
          }
        },
        grid: {
          left: "2%",
          right: "2.2%",
          top: "0",
          bottom: "-8%",
          containLabel: true
        },
        xAxis: {
          show: false
        },
        yAxis: {
          type: "category",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            color: "#c9d1d9"
          },
          min: "20%"
        },
        dataset: {
          source: [
            ["1 star", this.widget.stats.s1],
            ["2 stars", this.widget.stats.s2],
            ["3 stars", this.widget.stats.s3],
            ["4 stars", this.widget.stats.s4],
            ["5 stars", this.widget.stats.s5]
          ],
          dimensions: ["rating", "count"]
        },
        series: [
          {
            name: "Rating",
            type: "bar",
            datasetIndex: 0,
            label: {
              show: true,
              precision: 1,
              valueAnimation: true,
              fontWeight: "bold",
              formatter(params) {
                const count = params.data[1];
                return count > 0 ? count : "";
              }
            },
            itemStyle: {
              borderRadius: [0, 5, 5, 0]
            },
            showBackground: true,
            backgroundStyle: {
              color: "rgba(31,111,235,0.1)"
            }
          }
        ]
      };
    }
  }
};
</script>
