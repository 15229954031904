<template>
  <BContainer class="mt-5 mb-2">
    <BRow>
      <BCol>
        <BRow>
          <BCol>
            <Header1 v-if="widget" title="Edit Widget" to="widget" v-bind:id="widget.id" v-bind:showPerma="true" />
            <WidgetForm v-if="widget" v-bind:widget="widget" />
          </BCol>
        </BRow>
        <BRow>
          <BCol>
            <Header2 title="Install" class="mt-5" />
            <WidgetInstall v-if="widget" v-bind:id="widget.id" />
          </BCol>
        </BRow>
        <BRow class="mt-4">
          <BCol>
            <p class="bg-dark rounded p-2 text-center">
              <small>
                <BIconInfoCircleFill color="orange" class="mr-1" />
                I'm looking for feedback on your experience. Send me an email if you're having trouble installing the
                script or if you can recommend changes. Thanks!
                <a v-bind:href="`mailto:${clientEmail}?subject=Install%20Feedback`">{{ clientEmail }}</a>
              </small>
            </p>
          </BCol>
        </BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Header1 from "./Header1.vue";
import Header2 from "./Header2.vue";
import WidgetForm from "./WidgetForm.vue";
import WidgetInstall from "./WidgetInstall.vue";

export default {
  metaInfo: {
    title: "Edit Widget"
  },
  components: {
    Header1,
    Header2,
    WidgetForm,
    WidgetInstall
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      clientEmail: process.env.VUE_APP_CLIENT_EMAIL
    };
  },
  computed: {
    widget() {
      return this.$store.getters["widget/widgetById"](this.id);
    }
  },
  created() {
    this.$store.commit("widget/setWidgetActive", this.id);
  }
};
</script>
