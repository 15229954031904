<template>
  <BForm novalidate v-on:submit.prevent>
    <BFormGroup label-class="font-weight-bold pt-0" label-cols-lg="1" label="From:">
      <BFormGroup id="websiteNameInputGroup" label-for="websiteNameInput" label="Business, Website or Product Name">
        <BFormRow>
          <BCol lg="6">
            <BFormInput
              aria-describedby="websiteNameInputText websiteNameInputLiveFeedback"
              id="websiteNameInput"
              type="text"
              v-bind:state="$v.form.websiteName.$dirty ? !$v.form.websiteName.$error : null"
              v-model="form.websiteName"
            />
            <BFormInvalidFeedback id="websiteNameInputLiveFeedback">
              Website or Product name is required
            </BFormInvalidFeedback>
          </BCol>
          <BCol lg="6">
            <BFormText id="websiteNameInputText" class="mt-2" v-if="form.websiteName">
              {{ form.websiteName.length }} / 25
            </BFormText>
          </BCol>
        </BFormRow>
      </BFormGroup>

      <BFormGroup id="websiteInputGroup" label-for="websiteInput" label="My Website">
        <BFormRow>
          <BCol lg="6">
            <BFormInput
              aria-describedby="websiteInputText websiteInputLiveFeedback"
              id="websiteInput"
              type="url"
              v-bind:state="$v.form.website.$dirty ? !$v.form.website.$error : null"
              v-model="form.website"
            />
            <BFormInvalidFeedback id="websiteInputLiveFeedback">
              Website is required and must be a valid url
            </BFormInvalidFeedback>
          </BCol>
          <BCol lg="6">
            <BFormText id="websiteInputText" class="mt-2"><b>Example:</b> https://www.my-website.com</BFormText>
          </BCol>
        </BFormRow>
      </BFormGroup>
    </BFormGroup>

    <BFormGroup label-class="font-weight-bold pt-0" label-cols-lg="1" label="To:">
      <BFormGroup id="reviewWebsiteNameInputGroup" label-for="reviewWebsiteNameInput" label="Review Website Name">
        <BFormRow>
          <BCol lg="6">
            <BFormInput
              aria-describedby="reviewWebsiteNameInputText reviewWebsiteNameInputLiveFeedback"
              id="reviewWebsiteNameInput"
              type="text"
              v-bind:state="$v.form.reviewWebsiteName.$dirty ? !$v.form.reviewWebsiteName.$error : null"
              v-model="form.reviewWebsiteName"
            />
            <BFormInvalidFeedback id="reviewWebsiteNameInputLiveFeedback">
              Review website name is required
            </BFormInvalidFeedback>
          </BCol>
          <BCol lg="6">
            <BFormText id="reviewWebsiteNameInputText" class="mt-2" v-if="form.reviewWebsiteName">
              {{ form.reviewWebsiteName.length }} / 25
            </BFormText>
          </BCol>
        </BFormRow>
      </BFormGroup>

      <BFormGroup id="reviewWebsiteInputGroup" label-for="reviewWebsiteInput" label="Review Website">
        <BFormRow>
          <BCol lg="6">
            <BFormInput
              aria-describedby="reviewWebsiteInputText reviewWebsiteInputLiveFeedback"
              id="reviewWebsiteInput"
              type="url"
              v-bind:state="$v.form.reviewWebsite.$dirty ? !$v.form.reviewWebsite.$error : null"
              v-model="form.reviewWebsite"
            />
            <BFormInvalidFeedback id="reviewWebsiteInputLiveFeedback">
              Review website is required and must be a valid url
            </BFormInvalidFeedback>
          </BCol>
          <BCol lg="6">
            <BFormText id="reviewWebsiteInputText" class="mt-2">
              <b>Example:</b> https://www.review-website.com/my-company-listing
            </BFormText>
          </BCol>
        </BFormRow>
      </BFormGroup>
    </BFormGroup>

    <BFormRow>
      <BCol offset-lg="1" lg="11">
        <BFormRow>
          <BCol v-if="widget" lg="6">
            <BButton
              class="float-right"
              type="submit"
              v-bind:disabled="loading"
              v-bind:to="{ name: 'widget', params: { id: form.id } }"
            >
              Cancel
            </BButton>
            <BButton type="submit" v-bind:disabled="loading" v-on:click="onUpdateWidget" variant="primary">
              Save
            </BButton>
            <BSpinner class="ml-2" label="saving..." small v-if="loading" />
          </BCol>

          <BCol v-else lg="6">
            <BButton type="submit" v-bind:disabled="loading" v-on:click="onCreateWidget" variant="primary">
              Save
            </BButton>
            <BSpinner class="ml-2" label="saving..." small v-if="loading" />
          </BCol>
        </BFormRow>
      </BCol>
    </BFormRow>
  </BForm>
</template>

<script>
import { mapState } from "vuex";
import { maxLength, minLength, required, url } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  props: {
    widget: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        reviewWebsite: null,
        reviewWebsiteName: null,
        website: null,
        websiteName: null,
        ...this.widget
      }
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reviewWebsite: {
        required,
        url
      },
      reviewWebsiteName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25)
      },
      website: {
        required,
        url
      },
      websiteName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25)
      }
    }
  },
  methods: {
    async onCreateWidget() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch("widget/createWidget", this.form);
        this.$v.$reset();
      }
    },
    async onUpdateWidget() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await this.$store.dispatch("widget/updateWidget", this.form);
        this.form = response;
        this.$v.$reset();
      }
    }
  }
};
</script>
