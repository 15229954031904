<template>
  <BRow>
    <BCol lg="4">
      <WidgetAverage class="chart" v-if="widget.stats" v-bind:id="id" />
    </BCol>
    <BCol lg="4">
      <WidgetRating class="chart" v-if="widget.stats" v-bind:id="id" />
    </BCol>
    <BCol lg="4">
      <WidgetOutbound class="chart" v-if="widget.stats" v-bind:id="id" />
    </BCol>
  </BRow>
</template>

<script>
import WidgetAverage from "./WidgetAverage.vue";
import WidgetOutbound from "./WidgetOutbound.vue";
import WidgetRating from "./WidgetRating.vue";

export default {
  components: {
    WidgetAverage,
    WidgetOutbound,
    WidgetRating
  },
  props: ["id"],
  computed: {
    widget() {
      return this.$store.getters["widget/widgetById"](this.id);
    }
  },
  watch: {
    "$route.params.id": {
      handler: function(value) {
        this.$store.dispatch("widget/statsByWidgetId", value);
      },
      // deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.chart {
  height: 275px;
}
</style>
