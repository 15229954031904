<template>
  <div id="install">
    <!-- static -->
    <div class="mb-4">
      <h3 v-b-toggle.static class="h4">
        <BIconArrowRight class="mr-2" />Static website, blog, or content management system (CMS)
      </h3>
      <BCollapse visible id="static">
        <p>Add the script and html tag before the closing <code>&lt;/body&gt;</code> section of your HTML.</p>
        <pre v-highlightjs class="border rounded p-3">
<code class="html">&lt;script type=&quot;module&quot; src=&quot;https://cdn.jsdelivr.net/npm/@reviewboostr/boostrjs@1.0.12/dist/boostr.js&quot;&gt;&lt;/script&gt;
&lt;review-boostr id=&quot;{{ id }}&quot;&gt;&lt;/review-boostr&gt;</code></pre>
      </BCollapse>
    </div>
    <!-- Vue.js -->
    <div class="mb-4">
      <h3 v-b-toggle.vuejs class="h4">
        <BIconArrowRight /><Icon name="brands/vuejs" color="#41b883" class="mx-2" />Vue.js
      </h3>
      <BCollapse id="vuejs">
        <p>
          Use npm or yarn to install the latest version. Import and register ReivewBoostr in your app entry point. Use
          in any component.
        </p>
        <pre v-highlightjs class="border rounded p-3">
<code class="bash"># With npm
npm install @reviewboostr/boostrjs

# With yarn
yarn add @reviewboostr/boostrjs</code></pre>
        <pre v-highlightjs class="border rounded p-3">
<code class="javascript">// main.js
import ReviewBoostr from &quot;@reviewboostr/boostrjs&quot;;

Vue.use(ReviewBoostr);

// Add to any Vue component
&lt;template&gt;
&lt;review-boostr id=&quot;{{ id }}&quot;&gt;&lt;/review-boostr&gt;
&lt;/template&gt;</code></pre>
      </BCollapse>
    </div>
    <!-- React -->
    <div class="mb-4">
      <h3 v-b-toggle.react class="h4">
        <BIconArrowRight />
        <Icon name="brands/react" color="#5ed3f3" class="mx-2" />React
      </h3>
      <BCollapse id="react">
        <p>Use npm or yarn to install the latest version. Import and use in any component.</p>
        <pre v-highlightjs class="border rounded p-3">
<code class="bash"># With npm
npm install @reviewboostr/boostrjs

# With yarn
yarn add @reviewboostr/boostrjs</code></pre>
        <pre v-highlightjs class="border rounded p-3">
<code>// App.js
import ReviewBoostr from &quot;@reviewboostr/boostrjs&quot;;

render() {
  return (
    &lt;review-boostr id=&quot;{{ id }}&quot;&gt;&lt;/review-boostr&gt;
  );
}</code></pre>
      </BCollapse>
    </div>
  </div>
</template>

<script>
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/brands/react";
import "vue-awesome/icons/brands/vuejs";

export default {
  components: {
    Icon
  },
  props: {
    id: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.collapsed .b-icon {
  transition: 0.3s transform ease-in;
}
.not-collapsed .b-icon {
  transition: 0.3s transform ease-out;
  transform: rotate(90deg);
}
</style>
