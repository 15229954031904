<template>
  <VChart class="chart" autoresize :option="option" />
</template>

<script>
import { GraphicComponent } from "echarts/components";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { use, registerTheme } from "echarts/core";
import boostr from "../eChartsBoostr";
import VChart, { THEME_KEY } from "vue-echarts";

registerTheme("boostr", boostr);

use([CanvasRenderer, GaugeChart, GraphicComponent]);

export default {
  name: "WidgetOutbound",
  components: {
    VChart
  },
  props: ["id"],
  provide: {
    [THEME_KEY]: "boostr"
  },
  computed: {
    widget() {
      return this.$store.getters["widget/widgetById"](this.id);
    },
    outboundPercent() {
      return this.widget.stats.outbound / this.widget.stats.s5;
    },
    option() {
      return {
        grid: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        },
        graphic: {
          type: "text",
          left: "center",
          top: "33%",
          draggable: true,
          style: {
            text: `${this.outboundPercent ? (this.outboundPercent * 100).toFixed(1) : 0}%`,
            fill: "rgba(240, 246, 252, 0.7)"
          }
        },
        series: [
          {
            type: "gauge",
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: [
                  [this.outboundPercent ? this.outboundPercent : 0, "rgba(31,111,235,1)"],
                  [1, "rgba(31,111,235,0.2)"]
                ],
                width: "40"
              }
            },
            axisTick: {
              show: false
            },
            data: [
              {
                name: "Outbound",
                value: this.widget.stats.outbound
              }
            ],
            detail: {
              offsetCenter: [0, "25%"],
              color: "#ffffff",
              fontSize: 40
            },
            endAngle: -270,
            startAngle: 90,
            pointer: {
              show: false
            },
            splitLine: {
              show: false
            },
            title: {
              offsetCenter: [0, -15],
              color: "#ffffff",
              fontSize: 18,
              fontWeight: "bold"
            }
          }
        ]
      };
    }
  }
};
</script>
