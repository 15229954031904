<template>
  <BContainer class="my-5">
    <BRow>
      <BCol>
        <BRow>
          <BCol>
            <Header1 title="Create Widget" description="Encourage happy customers to leave reviews." />
            <WidgetForm />
          </BCol>
        </BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import WidgetForm from "./WidgetForm.vue";
import Header1 from "./Header1.vue";

export default {
  components: {
    WidgetForm,
    Header1
  }
};
</script>
